<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons.case_study">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Case Study</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this Case Study?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>

						<v-col cols="12">
							<v-select v-model="case_study.status" :items="resourceStatuses" item-text="label" item-value="value" label="Status"></v-select>
						</v-col>

						<v-col cols="12">
							<v-switch v-model="case_study.premium" label="Premium"></v-switch>
						</v-col>

						<v-col cols="12" v-if="case_study.premium">
							<ti-date-picker v-model="case_study.premium_until" label="Premium until"></ti-date-picker>
						</v-col>

						<v-col cols="12">
							<ti-image-upload v-model="case_study.featured_image_id" label="Featured image" :image-id="case_study.featured_image_id" :key="case_study.id"></ti-image-upload>
						</v-col>

					</template>

					<template>
						<v-col cols="12">
							<v-text-field v-model="case_study.title" :rules="validations.title" label="Title"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="case_study.subtitle" :rules="validations.subtitle" label="Subtitle"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-select
								v-model="case_study.topics_attributes"
								:items="topics"
								label="Topics"
								multiple
								item-text="name"
								item-value="id"
								return-object
								chips
								persistent-hint
							></v-select>
						</v-col>

						<v-col cols="12" class="mt-5">
							<ti-expertise-slider v-model="case_study.expertise"></ti-expertise-slider>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>
			<ti-card :icon="$icons.seo">
				<template #title>SEO</template>
				<template>
					<v-row>
						<v-col cols="12" lg="6">
							<v-text-field v-model="case_study.meta_title" label="Meta title" :rules="validations.meta_title"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6">
							<ti-keywords v-model="case_study.meta_keywords" :key="case_study.id"></ti-keywords>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="case_study.meta_description" label="Meta description" :rules="validations.meta_description"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="case_study.slug" :rules="validations.slug" label="Slug"></v-text-field>
						</v-col>

						<v-col cols="12">
							<ti-google-preview :url="case_study.slug" :meta-title="case_study.meta_title" :meta-description="case_study.meta_description" url-prefix="case_studies"></ti-google-preview>
						</v-col>
					</v-row>
				</template>
			</ti-card>
			<ti-card :icon="$icons.content">
				<template #title>Content</template>
				<template>
					<v-row>
						<v-col cols="12">
							<ti-editor v-model="case_study.content" no-title></ti-editor>
						</v-col>
					</v-row>
				</template>
			</ti-card>
			<ti-card :icon="$icons.redirect">
				<template #title>Redirects</template>
				<template>
					<div v-for="(redirect, index) in case_study.url_redirects_attributes">
						<v-row v-if="!redirect._destroy">
							<v-col>
								<v-text-field v-model="redirect.redirect_from" :rules="validations.redirect" label="From"></v-text-field>
							</v-col>
							<v-col >
								<v-text-field v-model="redirect.redirect_to" :rules="validations.redirect" label="To"></v-text-field>
							</v-col>
							<v-col class="shrink">
								<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteRedirect(redirect, index)">
									<v-icon>{{ $icons.delete }}</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</div>

					<v-row>
						<v-col>
							<v-btn color="success" elevation="2" fab small class="mr-4" @click="addRedirect">
								<v-icon>{{ $icons.new }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</template>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import helperMixin from "@/mixins/helperMixin";
import TiDatePicker from "@/components/TiDatePicker";
import models from '@/models'

export default {
	name: "CaseStudysForm",
	components: {TiDatePicker},
	props: ['id'],
	mixins: [helperMixin],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		case_study: sync('case_studies/caseStudy'),
		topics: sync('topics/topics'),
	},
	watch: {
		'case_study.title'(value) {
			if (!this.case_study.id || (this.case_study.id && !this.case_study.slug)) {
				this.case_study.slug = this.slugify(value)
			}
			if (!this.case_study.id || (this.case_study.id && !this.case_study.meta_title)) {
				this.case_study.meta_title = value
			}
		},
		'case_study.subtitle'(value) {
			if (!this.case_study.id || (this.case_study.id && !this.case_study.meta_description)) {
				this.case_study.meta_description = value
			}
		}
	},
	data: () => ({
		image: '',
		fab: false,
		subDrawer: false,
		valid: false,
		validations: {
			title: validationRules('Title', ['required', 'minLength:2']),
			meta_title: validationRules('Meta title', ['required', 'minLength:2']),
			meta_description: validationRules('Meta description', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('topics/getTopics!', null)
			if (this.isEdit) {
				this.$store.set('case_studies/getCaseStudy!', this.id)
			} else {
				this.case_study = new models.Resource('CaseStudy')
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.case_studies.update(this.id, this.case_study)
						.then(response => {
							this.$toast.add('Case Study updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'CaseStudies'})
						})
						.catch(error => {
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.case_studies.create(this.case_study)
						.then(response => {
							this.$toast.add('Case Study created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'CaseStudies'})
						})
						.catch(error => {
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.case_studies.delete(this.id, this.case_study)
				.then(response => {
					this.$toast.add('CaseStudy deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'CaseStudys'})
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
		},
		addRedirect(){
			this.case_study.url_redirects_attributes.push(models.UrlRedirect())
		},
		deleteRedirect(redirect, index){
			if(redirect.id !== null){
				this.case_study.url_redirects_attributes[index]['_destroy'] = true
			}
			else {
				this.case_study.url_redirects_attributes.splice(index, 1)
			}
		}
	}
}
</script>